import React, { useEffect, useState } from "react"
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react"

import BillingInformation from "../components/checkout/billingInformation"
import CheckoutNav from "../components/checkout/checkoutNav"
import { Elements } from "@stripe/react-stripe-js"
import EventInfo from "../components/checkout/eventInfo"
import InvoiceGenerated from "../components/checkout/invoiceGenerated"
import Layout from "../components/layout/layout"
import Loading from "../components/loading"
import OrderConfirmation from "../components/checkout/orderConfirmation"
import PaymentDetails from "../components/checkout/paymentDetails"
import Redirecting from "../components/redirecting"
import ShippingInformation from "../components/checkout/shippingInformation"
import StepWizard from "react-step-wizard"
import TicketHolderInformation from "../components/checkout/ticketHolderInformation"

import { loadStripe } from "@stripe/stripe-js"

import queryString from "query-string"
import {
	useCheckout,
	CheckoutProvider,
} from "../contexts/checkout.provider.jsx"

const PageComponent = ({ location }) => {
	const qs = queryString.parse(location.search)
	const stripePromise = loadStripe(process.env.GATSBY_STRIPE_API_KEY)

	return (
		<Elements stripe={stripePromise}>
			<CheckoutProvider>
				<CheckoutWizard qs={qs} />
			</CheckoutProvider>
		</Elements>
	)
}

const CheckoutWizard = ({ qs }) => {
	const { getOrder, checkTickets, order, invoiceId, setQueryString } =
		useCheckout()
	const [stepWizard, setStepWizard] = useState()

	useEffect(() => {
		const orderId = qs.orderId
		setQueryString(qs)

		try {
			checkTickets()
		} catch {}

		if (orderId) {
			getOrder(qs)
		}
	}, [])

	if (!order) {
		return (
			<Layout>
				<div className="text-center flex h-screen justify-center items-center mb-15">
					<Loading />
				</div>
			</Layout>
		)
	}

	if (invoiceId) {
		return (
			<Layout ClassNames={"padding-overwrite min-h-screen"}>
				<InvoiceGenerated invoiceId={invoiceId} />
			</Layout>
		)
	}

	const determineCheckout = () => {
		if (order?.orderDetails[0].price === 0) {
			return (
				<ComplimentaryCheckout
					stepWizard={stepWizard}
					setStepWizard={setStepWizard}
				/>
			)
		} else {
			return (
				<FullCheckout stepWizard={stepWizard} setStepWizard={setStepWizard} />
			)
		}
	}

	return determineCheckout()
}

const FullCheckout = ({ stepWizard, setStepWizard }) => {
	const {
		order,
		membership,
		setInvoiceId,
		createdInvoicedId,
		setCreatedInvoicedId,
	} = useCheckout()

	return (
		<Layout ClassNames={"padding-overwrite min-h-screen"}>
			<CheckoutNav
				stepWizardState={stepWizard?.state}
				isDisabled={membership}
			/>
			<EventInfo name={order.productName} />

			<StepWizard
				isHashEnabled={true}
				isLazyMount={true}
				instance={setStepWizard}
			>
				<TicketHolderInformation
					stepName="Ticket Holder Information"
					hashKey="TicketHolderInformation"
				/>
				<BillingInformation
					stepName="Billing Information"
					hashKey="BillingInformation"
				/>
				{!membership && (
					<ShippingInformation
						stepName={"Shipping Information"}
						hashKey={"ShippingInformation"}
					/>
				)}
				<PaymentDetails stepName="Payment Details" hashKey="PaymentDetails" />
				<OrderConfirmation
					stepName="Confirm"
					hashKey="Confirm"
					invoiceGenerated={id => setInvoiceId(id)}
					invoicedId={createdInvoicedId}
					setInvoicedId={id => setCreatedInvoicedId(id)}
				/>
			</StepWizard>
		</Layout>
	)
}

/**
 * 
	- check if member 
		- True
			- Checkout pages 1 (Ticket Holder Info) and 5 (Confirm)
		- False
			- Costs Money?
				- True	
					- Checkout pages 1 (Ticket Info),2 (Billing Info),4 (Payment Details),5(Confirm)
				- False
					- Checkout pages 1 (Ticket Holder Info) and 5 (Confirm)
 */
const ComplimentaryCheckout = ({ stepWizard, setStepWizard }) => {
	const {
		order,
		membership,
		setInvoiceId,
		createdInvoicedId,
		setCreatedInvoicedId,
	} = useCheckout()
	return (
		<Layout ClassNames={"padding-overwrite min-h-screen"}>
			<CheckoutNav
				stepWizardState={stepWizard?.state}
				isDisabled={membership}
			/>
			<EventInfo name={order.productName} />

			<StepWizard
				isHashEnabled={true}
				isLazyMount={true}
				instance={setStepWizard}
			>
				<TicketHolderInformation
					stepName="Ticket Holder Information"
					hashKey="TicketHolderInformation"
				/>
				<OrderConfirmation
					stepName="Confirm"
					hashKey="Confirm"
					invoiceGenerated={id => setInvoiceId(id)}
					invoicedId={createdInvoicedId}
					setInvoicedId={id => setCreatedInvoicedId(id)}
				/>
			</StepWizard>
		</Layout>
	)
}

export default withAuthenticationRequired(PageComponent, {
	onRedirecting: () => <Redirecting />,
})
